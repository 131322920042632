import { store } from "@/store/store";
import { pantalla_modo_lista } from "@/shared/dtos/dynamic_tables/pantalla_modo_lista";
import { tipo_de_dato } from "@/shared/dtos/dynamic_tables/tipo_de_dato";
import { columna } from "@/shared/dtos/dynamic_tables/columna";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { pantalla_lista } from "@/shared/dtos/dynamic_tables/pantalla_lista";

@Module({
  namespaced: true,
  name: "pantalla_modo_listaModule",
  store,
  dynamic: true,
})
class pantalla_modo_listaModule extends VuexModule {
  public pantalla_modo_lista: pantalla_modo_lista = new pantalla_modo_lista();
  public pantalla_modo_listas: pantalla_modo_lista[] = [];
  public tipos_dato: tipo_de_dato[] = [];

  @Action({ commit: "onGetpantalla_modo_lista" })
  public async getpantalla_modo_lista(id: any) {
    return await ssmHttpService.get(API.pantalla_lista + "/pantalla/" + id);
  }

  @Action
  public async get_columna_pantalla(id: any) {
    return await ssmHttpService.get(API.pantalla_lista + "/Columna/" + id);
  }

  @Mutation
  public onGetpantalla_modo_lista(res: pantalla_modo_lista) {
    this.pantalla_modo_lista = res;
  }

  @Action({ commit: "onGetpantalla_modo_listas" })
  public async getpantalla_modo_listas() {
    return await ssmHttpService.get(API.pantalla_lista);
  }

  @Action
  public async getNextIdColumna() {
    return await ssmHttpService.get(
      API.pantalla_lista + "/NextIdColumnaPantalla"
    );
  }

  @Action
  public async getNextIdPantalla() {
    return await ssmHttpService.get(API.pantalla_lista + "/NextIdPantalla");
  }

  @Action
  public async getTiposDatos(): Promise<tipo_de_dato[]> {
    return await ssmHttpService.get(API.pantalla_lista + "/TiposDato");
  }

  @Action
  public async guardarpantalla_modo_lista(pantalla_modo_lista: pantalla_lista) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.pantalla_lista,
      pantalla_modo_lista.toJson()
    );
  }

  @Action
  public async modificarpantalla_modo_lista(
    pantalla_modo_lista: pantalla_lista
  ) {
    await ssmHttpService.put(
      API.pantalla_lista + "/" + pantalla_modo_lista.id,
      pantalla_modo_lista
    );
  }

  @Action
  public async guardarColumnaPantalla(columna_pantalla: columna) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.pantalla_lista + "/AddColumna",
      columna_pantalla.toJson()
    );
  }

  @Action
  public async modificarColumnaPantalla(columna_pantalla: columna) {
    await ssmHttpService.put(
      API.pantalla_lista + "/UpdateColumna",
      columna_pantalla
    );
  }

  @Action
  public async eliminarpantalla_modo_lista(
    pantalla_modo_lista: pantalla_modo_lista
  ) {
    await ssmHttpService.delete(
      API.pantalla_lista + "/" + pantalla_modo_lista.id,
      null,
      false
    );
    this.getpantalla_modo_listas();
  }

  @Mutation
  public onGetpantalla_modo_listas(res: pantalla_modo_lista[]) {
    this.pantalla_modo_listas = res
      ? res.map((x) => new pantalla_modo_lista(x))
      : [];
  }
}
export default getModule(pantalla_modo_listaModule);
