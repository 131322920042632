import { store } from "@/store/store";
import { enumeracion } from "@/shared/dtos/enumeracion";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { Cabecera_Enumeraciones_FormularioDto } from "@/shared/dtos/Cabecera_Enumeraciones_FormularioDto";

@Module({
  namespaced: true,
  name: "enumeracionModule",
  store,
  dynamic: true,
})
class enumeracionModule extends VuexModule {
  public enumeraciones: enumeracion[] = [];
  public enumeracion: enumeracion = new enumeracion();
  public enumeracion_formulario: Cabecera_Enumeraciones_FormularioDto = new Cabecera_Enumeraciones_FormularioDto();

  @Action({ commit: "onGetenumeraciones" })
  public async getenumeraciones() {
    return await ssmHttpService.get(API.enumeracion);
  }

  @Action({ commit: "onGetenumeraciones_cast" })
  public async getenumeraciones_fromtipo(id: any) {
    return await ssmHttpService.get(API.enumeracion + "/enum_from_tipo/" + id);
  }


  @Action({ commit: "onGetenumeracionForm" })
  public async getenumeraciones_forform(id: any) {
    return await ssmHttpService.get(API.enumeracion + "/enum_from_tipo/" + id);
  }

  @Action({ commit: "onGetenumeracion" })
  public async getenumeracion(id: any) {
    return await ssmHttpService.get(API.enumeracion + "/" + id);
  }

  @Action
  public async guardarenumeracion(enumeracion: enumeracion) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.enumeracion, enumeracion.toJson());
  }

  @Action
  public async modificarenumeracion(enumeracion: enumeracion) {
    return await ssmHttpService.put(
      API.enumeracion + "/" + enumeracion.id,
      enumeracion
    );
  }

  @Action
  public async modificarenumeracion_lista(enumeracion: enumeracion[]) {
    return await ssmHttpService.put(
      API.enumeracion + "/save_enums/",
      enumeracion
    );
  }

  @Action
  public async eliminarenumeracion(enumeracion: enumeracion) {
    return await ssmHttpService.delete(
      API.enumeracion + "/" + enumeracion.id,
      null,
      false
    );
  }

  @Mutation
  public onGetenumeraciones_cast(res: Cabecera_Enumeraciones_FormularioDto) {
    this.enumeraciones = res.enumeraciones;
  }

  @Mutation
  public onGetenumeraciones(res: enumeracion[]) {
    this.enumeraciones = res ? res.map((x) => new enumeracion(x)) : [];
  }

  @Mutation
  public onGetenumeracion(res: enumeracion) {
    this.enumeracion = new enumeracion(res);
  }

  @Mutation
  public onGetenumeracionForm(res: Cabecera_Enumeraciones_FormularioDto) {
    this.enumeracion_formulario = res;
  }
}
export default getModule(enumeracionModule);
